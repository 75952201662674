export const loadTableConstants = {
    GETALL_REQUEST: 'GETALL_REQUEST',
    GETALL_REQUEST1: 'GETALL_REQUEST',
    GETALL_REQUEST2: 'GETALL_REQUEST',
    GETALL_REQUEST3: 'GETALL_REQUEST',
    GETALL_REQUEST4: 'GETALL_REQUEST',
    GETALL_REQUEST5: 'GETALL_REQUEST',
    GETALL_SUCCESS: 'GETALL_SUCCESS',
    GETALL_SUCCESS1: 'GETALL_SUCCESS',
    GETALL_SUCCESS2: 'GETALL_SUCCESS',
    GETALL_SUCCESS3: 'GETALL_SUCCESS',
    GETALL_SUCCESS4: 'GETALL_SUCCESS',
    GETALL_SUCCESS5: 'GETALL_SUCCESS',
    GETALL_FAILURE: 'GETALL_FAILURE',
    GETALL_FAILURE1: 'GETALL_FAILURE',
    GETALL_FAILURE2: 'GETALL_FAILURE',
    GETALL_FAILURE3: 'GETALL_FAILURE',
    GETALL_FAILURE4: 'GETALL_FAILURE',
    GETALL_FAILURE5: 'GETALL_FAILURE',
};