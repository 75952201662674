import { loadTableConstants } from './constants';
import { toastr } from 'react-redux-toastr';
import { handleResponse } from 'helpers';

export const loadTableActions = {
    getAll, getAll1, getAll2, getAll3, getAll4, getAll5
};

const API_URL = process.env.REACT_APP_API_URL;
let now     = new Date(),
  token     = localStorage.getItem("token"),
  jsonToken = JSON.parse(token);
  now.setHours(now.getHours())

function getAll(url) {
   
    return dispatch => {
        dispatch( { type: loadTableConstants.GETALL_REQUEST } );

            // window.fetch(API_URL + url , {
            //         method: "GET",
            //         headers: {
            //         "Content-Type": "application/json",
            //         'x-access-token': tokenVerify.accessToken
            //         // Authorization: `Bearer ${tokenVerify.accessToken}`,
            //         }
            //     });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': jsonToken.accessToken
                        // Authorization: `Bearer ${tokenVerify.accessToken}`,
                        }
                };


                return fetch(API_URL + url, requestOptions)
                .then(handleResponse)
                .then(response => {
                    dispatch( { type: loadTableConstants.GETALL_SUCCESS, data: response } );

                    return {status: true}
                })
                .catch(error => {
                    dispatch({ type: loadTableConstants.GETALL_FAILURE, message1: error });
                    toastr.error('', error)

                    return {status: false}
                });
    };
}

function getAll1(url) {
    
    

    return dispatch => {
        dispatch( { type: loadTableConstants.GETALL_REQUEST1 } );

            // window.fetch(API_URL + url , {
            //         method: "GET",
            //         headers: {
            //         "Content-Type": "application/json",
            //         'x-access-token': tokenVerify.accessToken
            //         // Authorization: `Bearer ${tokenVerify.accessToken}`,
            //         }
            //     });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': jsonToken.accessToken
                        // Authorization: `Bearer ${tokenVerify.accessToken}`,
                        }
                };


                return fetch(API_URL + url, requestOptions)
                .then(handleResponse)
                .then(response1 => {
                    dispatch( { type: loadTableConstants.GETALL_SUCCESS1, data1: response1 } );

                    return {status: true}
                })
                .catch(error => {
                    dispatch({ type: loadTableConstants.GETALL_FAILURE1, message1: error });
                    toastr.error('', error)

                    return {status: false}
                });
    };
}
function getAll2(url) {
    return dispatch => {
        dispatch( { type: loadTableConstants.GETALL_REQUEST2 } );

            // window.fetch(API_URL + url , {
            //         method: "GET",
            //         headers: {
            //         "Content-Type": "application/json",
            //         'x-access-token': tokenVerify.accessToken
            //         // Authorization: `Bearer ${tokenVerify.accessToken}`,
            //         }
            //     });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': jsonToken.accessToken
                        // Authorization: `Bearer ${tokenVerify.accessToken}`,
                        }
                };


                return fetch(API_URL + url, requestOptions)
                .then(handleResponse)
                .then(response2 => {
                    dispatch( { type: loadTableConstants.GETALL_SUCCESS2, data2: response2 } );

                    return {status: true}
                })
                .catch(error => {
                    dispatch({ type: loadTableConstants.GETALL_FAILURE2, message2: error });
                    toastr.error('', error)

                    return {status: false}
                });
    };
}
function getAll3(url) {

    return dispatch => {
        dispatch( { type: loadTableConstants.GETALL_REQUEST3 } );

            // window.fetch(API_URL + url , {
            //         method: "GET",
            //         headers: {
            //         "Content-Type": "application/json",
            //         'x-access-token': tokenVerify.accessToken
            //         // Authorization: `Bearer ${tokenVerify.accessToken}`,
            //         }
            //     });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': jsonToken.accessToken
                        // Authorization: `Bearer ${tokenVerify.accessToken}`,
                        }
                };


                return fetch(API_URL + url, requestOptions)
                .then(handleResponse)
                .then(response3 => {
                    dispatch( { type: loadTableConstants.GETALL_SUCCESS3, data3: response3 } );

                    return {status: true}
                })
                .catch(error => {
                    dispatch({ type: loadTableConstants.GETALL_FAILURE3, message3: error });
                    toastr.error('', error)

                    return {status: false}
                });
    };
}
function getAll4(url) {

    return dispatch => {
        dispatch( { type: loadTableConstants.GETALL_REQUEST4 } );

            // window.fetch(API_URL + url , {
            //         method: "GET",
            //         headers: {
            //         "Content-Type": "application/json",
            //         'x-access-token': tokenVerify.accessToken
            //         // Authorization: `Bearer ${tokenVerify.accessToken}`,
            //         }
            //     });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': jsonToken.accessToken
                        // Authorization: `Bearer ${tokenVerify.accessToken}`,
                        }
                };


                return fetch(API_URL + url, requestOptions)
                .then(handleResponse)
                .then(response4 => {
                    dispatch( { type: loadTableConstants.GETALL_SUCCESS4, data4: response4 } );

                    return {status: true}
                })
                .catch(error => {
                    dispatch({ type: loadTableConstants.GETALL_FAILURE4, message4: error });
                    toastr.error('', error)

                    return {status: false}
                });
    };
}
function getAll5(url) {

    return dispatch => {
        dispatch( { type: loadTableConstants.GETALL_REQUEST5 } );

            // window.fetch(API_URL + url , {
            //         method: "GET",
            //         headers: {
            //         "Content-Type": "application/json",
            //         'x-access-token': tokenVerify.accessToken
            //         // Authorization: `Bearer ${tokenVerify.accessToken}`,
            //         }
            //     });
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        'x-access-token': jsonToken.accessToken
                        // Authorization: `Bearer ${tokenVerify.accessToken}`,
                        }
                };


                return fetch(API_URL + url, requestOptions)
                .then(handleResponse)
                .then(response5 => {
                    dispatch( { type: loadTableConstants.GETALL_SUCCESS5, data5: response5 } );

                    return {status: true}
                })
                .catch(error => {
                    dispatch({ type: loadTableConstants.GETALL_FAILURE5, message5: error });
                    toastr.error('', error)

                    return {status: false}
                });
    };
}