import { loadTableConstants } from './constants';

const initialState = {
  loading: false,
  loading1: false,
  loading2: false,
  loading3: false,
  loading4: false,
  loading5: false,
  data: null,
  data1: null,
  data2: null,
  data3: null,
  data4: null,
  data5: null,
  message: '',
  message1: '',
  message2: '',
  message3: '',
  message4: '',
  message5: ''
}

export function loadTable (state = initialState, action) {
  switch (action.type) {
    case loadTableConstants.GETALL_REQUEST:
      return {
        loading: true,
        loading1: true
      };
    case loadTableConstants.GETALL_SUCCESS:
      return {
        loading: false,
        data: action.data,
        loading1: false,
        data1: action.data1,
      };
    case loadTableConstants.GETALL_FAILURE:
      return { 
        loading: false,
        message: action.message,
        loading1: false,
        message1: action.message1
      };
    default:
      return state
  }
}
export function loadTable1 (state = initialState, action) {
  switch (action.type) {
    case loadTableConstants.GETALL_REQUEST1:
      return {
        loading: true
      };
    case loadTableConstants.GETALL_SUCCESS1:
      return {
        loading: false,
        data1: action.data1,
      };
    case loadTableConstants.GETALL_FAILURE1:
      return { 
        loading: false,
        message1: action.message1
      };
    default:
      return state
  }
}
export function loadTable2 (state = initialState, action) {
  switch (action.type) {
    case loadTableConstants.GETALL_REQUEST2:
      return {
        loading: true
      };
    case loadTableConstants.GETALL_SUCCESS2:
      return {
        loading: false,
        data2: action.data2,
      };
    case loadTableConstants.GETALL_FAILURE2:
      return { 
        loading: false,
        message2: action.message2
      };
    default:
      return state
  }
}

export function loadTable3 (state = initialState, action) {
  switch (action.type) {
    case loadTableConstants.GETALL_REQUEST3:
      return {
        loading: true
      };
    case loadTableConstants.GETALL_SUCCESS3:
      return {
        loading: false,
        data3: action.data3,
      };
    case loadTableConstants.GETALL_FAILURE3:
      return { 
        loading: false,
        message3: action.message3
      };
    default:
      return state
  }
}

export function loadTable4 (state = initialState, action) {
  switch (action.type) {
    case loadTableConstants.GETALL_REQUEST4:
      return {
        loading: true
      };
    case loadTableConstants.GETALL_SUCCESS4:
      return {
        loading: false,
        data4: action.data4,
      };
    case loadTableConstants.GETALL_FAILURE4:
      return { 
        loading: false,
        message4: action.message4
      };
    default:
      return state
  }
}

export function loadTable5 (state = initialState, action) {
  switch (action.type) {
    case loadTableConstants.GETALL_REQUEST5:
      return {
        loading: true
      };
    case loadTableConstants.GETALL_SUCCESS5:
      return {
        loading: false,
        data5: action.data5,
      };
    case loadTableConstants.GETALL_FAILURE5:
      return { 
        loading: false,
        message5: action.message5
      };
    default:
      return state
  }
}

