import { combineReducers } from 'redux';
import { auth } from './auth/reducers';
import { alert } from './alert/reducers';
import { loadTable, loadTable1, loadTable2, loadTable3, loadTable4, loadTable5 } from './loadTable/reducers';
import { submitForm } from './submitForm/reducers';
import { modal } from './modal/reducers';
import { theme } from './theme/reducers';
import {reducer as toastrReducer} from 'react-redux-toastr'

export const rootReducer = combineReducers({
  auth,
  alert,
  loadTable,
  loadTable1,
  loadTable2,
  loadTable3,
  loadTable4,
  loadTable5,
  submitForm,
  modal,
  theme,
  toastr: toastrReducer
});
